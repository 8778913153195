import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Card,
  Table,
  Button,
  TimePicker,
  Switch,
  Input,
  InputNumber,
  Popconfirm,
  Modal,
} from "antd";
import socket from "../util/WebSocketManager";

import PrintDetails from "../util/tarjetasImpresion";
import OrderComponent from "./detallesOrdenes";
import ReactToPrint from "react-to-print";

import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  InfoOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleApproval,
  toggleSwitch,
  updateField,
  deleteRow,
  addProduct,
  adjustTime,
  syncOrderData,
  deleteOrderAction,
  fetchOrders,
} from "../redux/multiStepFormSlice";

import axios from "axios";
import ProductFetcher from "../productos/productosLista";
import isEqual from "lodash/isEqual";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const { RangePicker } = TimePicker;

const ClientCard = ({
  id,
  clientName,
  businessName,
  labelFilter,
  productNameFilter,
  variantFilter,
  matchesLabelFilter,
  matchesNameFilter,
  matchesVariantFilter,
}) => {
  const [editingKey, setEditingKey] = useState(null);
  const [editedQuantity, setEditedQuantity] = useState(null);
  const [editedNotas, setEditedNotas] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDetailOpen, setIsModalDetailOpen] = useState(false);
  const [isModalPrintOpen, setModalPrintOpen] = useState(false);

  const dispatch = useDispatch();
  const currentUserRole = useSelector(
    (state) => state.userManagement.currentUserRole
  );
  const allOrders = useSelector((state) => state.multiStepForm.allOrders);
  const order = allOrders.find((o) => o._id === id);
  const productList = order ? order.formData.tableData : [];
  const previousPayloadRef = useRef(null);

  const fetchData = useCallback(async () => {
    try {
      await dispatch(fetchOrders());
    } catch (error) {
      console.error("There was an error fetching the data", error);
    }
  }, [dispatch]);

  useEffect(() => {
    socket.on("orderDeleted", (data) => {
      fetchData();
    });
    return () => {
      socket.off("orderDeleted");
    };
  }, [fetchData]);

  useEffect(() => {
    const handleRowDeleted = (payload) => {
      dispatch(deleteRow(payload)); // dispatch the existing action when a row is deleted
    };

    socket.on("rowDeleted", handleRowDeleted);

    return () => {
      socket.off("rowDeleted", handleRowDeleted); // cleanup listener
    };
  }, [dispatch]);

  useEffect(() => {
    socket.on("productAdded", (payload) => {
      dispatch(syncOrderData(payload.id));
    });
    return () => {
      socket.off("productAdded");
    };
  }, [dispatch]);

  useEffect(() => {
    const handleRowEdited = (data) => {
      const { orderId, rowKey, newSwitchStatus, type } = data;

      if (type === "state") {
        dispatch(
          toggleSwitch({ id: orderId, key: rowKey, status: newSwitchStatus })
        );
      } else if (type === "approval") {
        dispatch(
          toggleApproval({ id: orderId, key: rowKey, status: newSwitchStatus })
        );
      } else if (["quantity", "subtotal", "notas"].includes(type)) {
        dispatch(
          updateField({
            id: orderId,
            key: rowKey,
            field: type,
            value: newSwitchStatus,
          })
        );
      }
    };

    socket.on("rowEdited", handleRowEdited);

    return () => {
      socket.off("rowEdited", handleRowEdited);
    };
  }, [dispatch]);

  const filteredProductList = productList.filter(
    (product) =>
      matchesLabelFilter(product, labelFilter) &&
      matchesNameFilter(product, productNameFilter) &&
      matchesVariantFilter(product, variantFilter)
  );

  ////////////

  useEffect(() => {
    const updateTimesHandler = (data) => {
      const unifiedPayload = {
        id: data.id,
        newProductionTime: new Date(data.newProductionTime),
        newDeliveryTime: new Date(data.newDeliveryTime),
        newTimeRange: data.newTimeRange.map((time) => new Date(time)),
      };

      if (!isEqual(previousPayloadRef.current, unifiedPayload)) {
        dispatch(adjustTime(unifiedPayload));
        previousPayloadRef.current = unifiedPayload;
      }
    };

    socket.on("updateTimes", updateTimesHandler);

    return () => {
      socket.off("updateTimes", updateTimesHandler);
    };
  }, [dispatch]);

  // Extract selectedOption along with other formData.
  const { selectedOption } = order ? order.formData : {};

  const timezoneOffset = process.env.REACT_APP_TIMEZONE_OFFSET * 60;

  const deliveryTime = order
    ? dayjs.utc(order.formData.deliveryTime).utcOffset(timezoneOffset)
    : dayjs.utc("00:00", "HH:mm");

  const productionTime = order
    ? dayjs.utc(order.formData.productionTime).utcOffset(timezoneOffset)
    : dayjs.utc("00:00", "HH:mm");

    const rangeStartTime = order
    ? dayjs.utc(order.formData.timeRange[0]).utcOffset(timezoneOffset)
    : dayjs.utc("00:00", "HH:mm");
  
  const rangeEndTime = order
    ? dayjs.utc(order.formData.timeRange[1]).utcOffset(timezoneOffset)
    : dayjs.utc("00:00", "HH:mm");
  
    const handleTimeChange = (type, time, timeString) => {
      if (timeString && id) {
        let payload = {
          newProductionTime:
            type === "production" ? time.toDate() : new Date(productionTime),
          newDeliveryTime:
            type === "delivery" ? time.toDate() : new Date(deliveryTime),
          newTimeRange:
            type === "range" && Array.isArray(time) && time.length === 2
              ? [
                  time[0].isValid() ? time[0].toDate() : rangeStartTime.toDate(),
                  time[1].isValid() ? time[1].toDate() : rangeEndTime.toDate(),
                ]
              : [rangeStartTime.toDate(), rangeEndTime.toDate()],
        };
        console.log("Dispatching payload:", payload);
        dispatch(adjustTime({ id, ...payload }));
      }
  };
  

  //////////////////

  //MODAL DETALLES

  const showModalDetail = () => {
    setIsModalDetailOpen(true);
  };

  const handleCancelDetails = () => {
    setIsModalDetailOpen(false);
  };
  //MODAL PRODUCTOS
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
  };

  //MODAL IMPERSION
  const showModalPrint = () => {
    setModalPrintOpen(true);
  };

  const handleCancelPrint = () => {
    setModalPrintOpen(false);
  };

  const handleOkPrint = async () => {
    setModalPrintOpen(false);
  };

  //MODAL AGREGAR PRODUCTO

  const fetchUpdatedData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_END_POINT}/ordenes/${id}`
      );
      if (response.status === 200 && response.data) {
      }
    } catch (error) {
      console.error("Failed to fetch updated data:", error);
    }
  }, [id]);

  const handleAddProduct = (newRow) => {
    // Dispatch the addProduct action from your Redux slice
    dispatch(addProduct({ newRow, id }));
  };

  //MODAL AGREGAR PRODUCTO

  const isEditing = (record) => {
    return record.key === editingKey;
  };

  const edit = (record) => {
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey(null);
  };

  const save = async (key) => {
    try {
      // Find the row that is being edited.
      const rowBeingEdited = productList.find((row) => row.key === key);

      if (!rowBeingEdited) {
        console.error("Row being edited not found");
        return;
      }

      // Get the individualPrice from the row
      const individualPrice = rowBeingEdited.price; // Adjust this if your naming is different.

      // Initialize a variable to hold the new subtotal
      let newSubtotal;

      if (editedQuantity !== null) {
        // Calculate the new subtotal
        newSubtotal = individualPrice * editedQuantity;

        // Your API call to update quantity
        await axios.patch(
          `${process.env.REACT_APP_NODE_END_POINT}/ordenes/${id}/${key}`,
          {
            type: "quantity",
            value: editedQuantity,
          }
        );

        // Your API call to update subtotal
        await axios.patch(
          `${process.env.REACT_APP_NODE_END_POINT}/ordenes/${id}/${key}`,
          {
            type: "subtotal",
            value: newSubtotal,
          }
        );

        // Update Redux store for quantity and subtotal
        dispatch(
          updateField({ id, key, field: "quantity", value: editedQuantity })
        );
        dispatch(
          updateField({ id, key, field: "subtotal", value: newSubtotal })
        );
      }

      if (editedNotas !== null) {
        // Your API call to update notas
        await axios.patch(
          `${process.env.REACT_APP_NODE_END_POINT}/ordenes/${id}/${key}`,
          {
            type: "notas",
            value: editedNotas,
          }
        );

        // Update Redux store for notas
        dispatch(updateField({ id, key, field: "notas", value: editedNotas }));
      }

      // Reset edited fields to null after saving
      setEditedQuantity(null);
      setEditedNotas(null);

      // Update the local state or dispatch a Redux action if needed
      setEditingKey(null);
    } catch (error) {}
  };

  const handleDelete = async (key) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_NODE_END_POINT}/ordenes/${id}/${key}`
      );

      // Update Redux store to remove the deleted row
      dispatch(deleteRow({ id, key }));

      await fetchUpdatedData();
    } catch (error) {
      console.error("Failed to delete row:", error);
      alert("Failed to delete row");
    }
  };

  const handleToggleSwitch = async (key, currentSwitchStatus) => {
    const newSwitchStatus = !currentSwitchStatus;
    try {
      await axios.patch(
        `${process.env.REACT_APP_NODE_END_POINT}/ordenes/${id}/${key}`,
        {
          type: "state",
          value: newSwitchStatus,
        }
      );

      dispatch(toggleSwitch({ id, key, status: newSwitchStatus }));
    } catch (error) {
      console.error("Failed to update state:", error);
    }
  };

  const handleToggleApproval = async (key, currentApprovalStatus) => {
    const newApprovalStatus = !currentApprovalStatus;
    try {
      await axios.patch(
        `${process.env.REACT_APP_NODE_END_POINT}/ordenes/${id}/${key}`,
        {
          type: "approval",
          value: newApprovalStatus,
        }
      );

      dispatch(toggleApproval({ id, key, status: newApprovalStatus }));
    } catch (error) {
      console.error("Failed to update approval:", error);
    }
  };

  const calculateTotalPrice = () => {
    const productTotal = productList.reduce(
      (acc, product) => acc + product.subtotal,
      0
    );
    const deliveryCost = parseFloat(order.formData.selectedValue.value);
    return productTotal + deliveryCost;
  };

  const deleteOrder = async () => {
    try {
      dispatch(deleteOrderAction(id)); // Using the thunk action for async operation and dispatch
      setIsModalDetailOpen(false); // Close the modal
    } catch (error) {
      console.error("Failed to delete order:", error);
    }
  };

  const renderFooter = () => {
    if (currentUserRole === "worker") {
      return null; // Return nothing if the user is a worker.
    }

    return (
      <div style={{ textAlign: "right" }}>
        <h4>Total: ${calculateTotalPrice()}</h4>
      </div>
    );
  };

  // Columns for the Ant Design Table
  const columns = [
    {
      title: "Producto",
      dataIndex: "name",
      key: "name",
      width: "15%",
    },
    {
      title: "Variedad",
      dataIndex: "variant",
      key: "variant",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      key: "quantity",

      render: (quantity, record) =>
        isEditing(record) ? (
          <InputNumber
            min={0} // optional: the minimum value the user can input
            defaultValue={quantity}
            onChange={(value) => setEditedQuantity(value)} // Note that value is a number here
          />
        ) : (
          quantity
        ),
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",

      render: (state, record) => (
        <Switch
          checked={state}
          checkedChildren={<div style={{ color: "white" }}>✓</div>}
          unCheckedChildren={<div style={{ color: "white" }}>✗</div>}
          onChange={() => handleToggleSwitch(record.key, state)}
          style={{
            backgroundColor: state ? "#18A558" : "#F7BC00",
          }}
        />
      ),
    },

    {
      title: "Area",
      dataIndex: "label",
      key: "label",
      className: "hide-column",
    },
    ...(currentUserRole !== "worker"
      ? [
          {
            title: "PrecioU",
            dataIndex: "price",
            key: "priceU",
            className: "hide-column",
          },
        ]
      : []),

    ...(currentUserRole !== "worker"
      ? [
          {
            title: "Precio",
            dataIndex: "subtotal",
            key: "price",
          },
        ]
      : []),
    {
      title: "Notas",
      dataIndex: "notas",
      key: "notes",

      render: (notas, record) =>
        isEditing(record) ? (
          <Input
            defaultValue={notas}
            onChange={(e) => setEditedNotas(e.target.value)}
          />
        ) : (
          notas
        ),
    },
    ...(currentUserRole !== "worker"
      ? [
          {
            title: "Entrega",
            dataIndex: "approval",
            key: "approval",

            render: (approval, record) => (
              <Switch
                checked={approval}
                checkedChildren={<div style={{ color: "white" }}>✓</div>}
                unCheckedChildren={<div style={{ color: "white" }}>✗</div>}
                onChange={() => handleToggleApproval(record.key, approval)}
                style={{
                  backgroundColor: approval ? "#1677FF" : "grey",
                }}
              />
            ),
          },
        ]
      : []),
    ...(currentUserRole !== "worker"
      ? [
          {
            title: "",
            key: "operations",

            render: (_, record) =>
              isEditing(record) ? (
                <div>
                  <Button type="link" onClick={() => save(record.key)}>
                    <CheckCircleOutlined />
                  </Button>
                  <Button type="link" onClick={cancel}>
                    <MinusCircleOutlined />
                  </Button>
                </div>
              ) : (
                <div>
                  <Button type="link" onClick={() => edit(record)}>
                    <EditOutlined />
                  </Button>

                  <Popconfirm
                    title="Confirmar elminiación de producto."
                    onConfirm={() => handleDelete(record.key)}
                    onCancel={() => console.log("User canceled delete")}
                    okText="Si"
                    cancelText="No"
                  >
                    <Button type="link">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </div>
              ),
          },
        ]
      : []),
  ];

  const componentRef = useRef();

  return (
    <Card>
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* First row: client name and buttons */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 style={{ display: "inline-block", marginRight: "10px" }}>
              {clientName} {businessName}
            </h3>
          
              <div style={{ display: "flex", alignItems: "center" }}>
                <Input
                  value={order.formData.notes}
                  style={{ width: "500px", marginRight: "30px" }}
                />
                    <Button
                  type="primary"
                  onClick={showModalDetail}
                  style={{ marginLeft: "10px" }}
                >
                  <InfoOutlined />
                </Button>
                  {currentUserRole !== "worker" && (
<div>
            
                <Button
                  type="primary"
                  onClick={showModal}
                  style={{ marginLeft: "10px" }}
                >
                  <PlusOutlined />
                </Button>
                <Button
                  type="primary"
                  onClick={showModalPrint}
                  style={{ marginLeft: "10px" }}
                >
                  <PrinterOutlined />
                </Button>
                </div>
                )}
              </div>
            
          </div>

          <Modal
            title="Detalles Despacho"
            open={isModalPrintOpen}
            onOk={handleOkPrint}
            onCancel={handleCancelPrint}
            footer={[
              <ReactToPrint
                key="print"
                trigger={() => <Button type="primary">Imprimir</Button>}
                content={() => componentRef.current}
              />,
              <Button key="close" onClick={handleOkPrint}>
                Cancelar
              </Button>,
            ]}
          >
            <PrintDetails ref={componentRef} orderId={id} />
          </Modal>

          <Modal
            open={isModalDetailOpen}
            onCancel={handleCancelDetails}
            footer={null}
          >
            <Card>
              <OrderComponent id={id} />
            </Card>

            <Card>
              <div style={{ color: "#1677FF" }}>
                <h2>Datos Cliente</h2>{" "}
              </div>
              <p>
                <strong>RUT:</strong> {order.formData.rut}
              </p>
              <p>
                <strong>Nombre:</strong> {order.formData.Nombre}
              </p>
              <p>
                <strong>Número:</strong> {order.formData.Numero}
              </p>
            </Card>
            <Popconfirm
              title="Confirmar borrar orden"
              onConfirm={deleteOrder}
              onCancel={null}
              okText="Si"
              cancelText="No"
            >
              <Button type="link">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Modal>
        </div>

        {/* Second row: time pickers and modal button */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
          className="responsiveTimeContainer"
        >
          <div>
            <span>Produccion: </span>
            <TimePicker
              format="HH:mm"
              onChange={(time, timeString) =>
                handleTimeChange("production", time, timeString)
              }
              value={dayjs(productionTime)}
            />
          </div>
          {currentUserRole !== "worker" && (
            <div>
              <span>Entrega: </span>
              <TimePicker
                format="HH:mm"
                onChange={(time, timeString) =>
                  handleTimeChange("delivery", time, timeString)
                }
                value={dayjs(deliveryTime)}
              />
            </div>
          )}
          {/* Display RangePicker if selectedOption is 'Despacho' */}
          {selectedOption === "Despacho" && currentUserRole !== "worker" && (
            <div>
              <span>Rango de Despacho: </span>
              <RangePicker
      format="HH:mm"
      onChange={(time, timeString) => {
        handleTimeChange("range", time, timeString);
      }}
      value={[rangeStartTime, rangeEndTime]}
    />
            </div>
          )}
        </div>
      </div>

      <Modal
        title="Agregar Producto:"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          // Custom footer, only keeping the OK button
          <Button key="ok" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <ProductFetcher orderId={id} addProduct={handleAddProduct} />
      </Modal>

      {/* Product List Table */}
      <div style={{ overflowX: "auto" }}>
        <Table
          dataSource={filteredProductList}
          columns={columns}
          pagination={{ pageSize: 5 }}
          footer={renderFooter}
        />
      </div>
    </Card>
  );
};

export default ClientCard;
