import React, { useEffect, useState, useCallback } from "react";
import ClientCard from "./tarjetaOrdenes"; // Adjust the import path accordingly
import { useDispatch, useSelector } from "react-redux"; // import hooks
import { fetchOrders } from "../redux/multiStepFormSlice"; // Update this path
import { Spin, Card, Select, Input, DatePicker, Pagination } from "antd";
import dayjs from "dayjs";
import io from "socket.io-client";
import utc from 'dayjs-plugin-utc';  


dayjs.extend(utc);


const { RangePicker } = DatePicker;
const { Option } = Select;

const matchesLabelFilter = (item, filter) =>
  filter === "All" || item?.label === filter;

const matchesNameFilter = (item, filter) =>
  !filter || item?.name?.toLowerCase().includes(filter.toLowerCase());

const matchesVariantFilter = (item, variantSearch) =>
  !variantSearch ||
  item?.variant?.toLowerCase().includes(variantSearch.toLowerCase());

const matchesClientOrBusinessName = (order, clientNameSearch) => {
  if (!clientNameSearch) return true;

  const clientNameMatch = order?.formData?.Nombre?.toLowerCase().includes(
    clientNameSearch.toLowerCase()
  );

  const businessNameMatch = order?.formData?.businessData?.nombreFantasia
    ?.toLowerCase()
    .includes(clientNameSearch.toLowerCase());

  return clientNameMatch || businessNameMatch;
};

const ListaOrdenes = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null); // New state for error
  const [selectedOptionFilter, setSelectedOptionFilter] = useState("All");
  const [labelFilter, setLabelFilter] = useState("All");
  const [productNameSearch, setProductNameSearch] = useState("");
  const [clientNameSearch, setClientNameSearch] = useState("");
  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);
  const [tempDateRange, setTempDateRange] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);
  const [approvalFilter, setApprovalFilter] = useState("ALL");
  const [variantSearch, setVariantSearch] = useState("");
 

  const resetFilters = () => {
    setSelectedOptionFilter("All");
    setLabelFilter("All");
    setProductNameSearch("");
    setClientNameSearch("");
    setApprovalFilter("ALL");
    setVariantSearch("");
  };

  const currentUserRole = useSelector(state => state.userManagement.currentUserRole);
  const allOrders = useSelector((state) => state.multiStepForm.allOrders);
  const currentUsername = useSelector(state => state.userManagement.currentUser);

  dayjs.extend(utc);

  
  const timezoneOffset = parseInt(process.env.REACT_APP_TIMEZONE_OFFSET) || 0;

const toLocalStartOfDay = useCallback(
  date => date.subtract(timezoneOffset, 'hour').startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'),
  [timezoneOffset]
);

const toLocalEndOfDay = useCallback(
  date => date.subtract(timezoneOffset, 'hour').endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'),
  [timezoneOffset]
);

const handleFetchOrders = useCallback((startDate, endDate) => {
  if (startDate && endDate) {
    const adjustedStartDate = toLocalStartOfDay(dayjs(startDate));
    const adjustedEndDate = toLocalEndOfDay(dayjs(endDate));
    dispatch(fetchOrders({ startDate: adjustedStartDate, endDate: adjustedEndDate }));
    console.log('Adjusted Start Date:', adjustedStartDate);
    console.log('Adjusted End Date:', adjustedEndDate);
  } else {
    dispatch(fetchOrders());
  }
}, [dispatch, toLocalStartOfDay, toLocalEndOfDay]);

const fetchData = useCallback(async () => {
  try {
    setIsLoading(true);
    setError(null);

    handleFetchOrders(dateRange[0], dateRange[1]);  

    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    setError("There was an error fetching the data.");
    console.error("There was an error fetching the data", error);
  }
}, [dateRange, handleFetchOrders]);

const onDateRangeChange = (dates) => {
  // Update the temporary date range state
  setTempDateRange(dates);
};

const onDateRangeConfirm = (open) => {
  if (!open) {
    if (tempDateRange && tempDateRange[0] && tempDateRange[1]) {
      handleFetchOrders(tempDateRange[0], tempDateRange[1]);
    } else {
      setDateRange([null, null]);
      handleFetchOrders(null, null);
    }
    resetFilters(); 
    setCurrentPage(1); 
  }
};


  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_NODE_END_POINT}`);

    fetchData(); // Initial fetch of orders

    socket.on("newOrder", (data) => {
      console.log("New order received:", data);
      console.log("User:", currentUsername);
      const today = new Date().toISOString().split("T")[0];

      if (data.deliveryDate.split("T")[0] === today) {
        const shouldNotifyUser = data.products.some(product => product.label === currentUsername);

        if (shouldNotifyUser) {
          const audio = new Audio('/notificacion.wav');
          audio.play();
        }

        fetchData(); // Refetch data to update UI
      }
    });

    return () => {
      socket.off("newOrder");
    };
  }, [fetchData, currentUsername]); // Add currentUserRole as a dependency



  const calculateTotalDisplayedQuantities = () => {
    return filteredOrders.reduce((totalQuantity, order) => {
      const orderTotalQuantity = order.formData.tableData.reduce((sum, product) => {
        // If a product matches the product name, variant, and label filters, then its quantity is considered
        if (
          matchesNameFilter(product, productNameSearch) && 
          matchesVariantFilter(product, variantSearch) &&
          matchesLabelFilter(product, labelFilter)
        ) {
          return sum + Number(product.quantity || 0);
        }
        return sum; // If not, just continue with the current sum
      }, 0);
      return totalQuantity + orderTotalQuantity;
    }, 0);
  };
  

  const calculateSumOfTotals = () => {
    return filteredOrders.reduce((acc, order) => {
      const orderTotal = order.formData.tableData.reduce(
        (sum, product) => sum + product.subtotal,
        0
      );
      const deliveryCost = Number(order.formData.selectedValue.value); // Assuming this is your delivery cost
      return acc + orderTotal + deliveryCost;
    }, 0);
  };


  const handleOrderDetailsClick = () => {
    console.log("Order Details Clicked");
    // Implement your logic for showing order details here
  };

  const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
  dayjs.extend(isSameOrAfter);

  const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
  dayjs.extend(isSameOrBefore);

  const handleClientNameSearchChange = (e) => {
    setClientNameSearch(e.target.value);
    setCurrentPage(1);
};

const handleProductNameSearchChange = (e) => {
    setProductNameSearch(e.target.value);
    setCurrentPage(1);
};

const handleVariantSearchChange = (e) => {
    setVariantSearch(e.target.value);
    setCurrentPage(1);
};

const handleOptionFilterChange = (value) => {
    setSelectedOptionFilter(value);
    setCurrentPage(1); 
};

const handleLabelFilterChange = (value) => {
    setLabelFilter(value);
    setCurrentPage(1);
};

const handleApprovalFilterChange = (value) => {
  setApprovalFilter(value);
  setCurrentPage(1);
};

  
  // Filter the orders based on selected values
  const filteredOrders = allOrders
    .filter((order) => {
      const matchesOption =
        selectedOptionFilter === "All" ||
        order?.formData?.selectedOption === selectedOptionFilter;
      const matchesLabel =
        order?.formData?.tableData?.some((product) =>
          matchesLabelFilter(product, labelFilter)
        ) || false;
      const matchesProductName =
        order?.formData?.tableData?.some((product) =>
          matchesNameFilter(product, productNameSearch)
        ) || false;
      const matchesVariant =
        order?.formData?.tableData?.some((product) =>
          matchesVariantFilter(product, variantSearch)
        ) || false;
      const matchesClientBusinessName = matchesClientOrBusinessName(
        order,
        clientNameSearch
      );
      const hasUnapprovedItems =
        order?.formData?.tableData?.some((item) => !item.approval) || false;
      const shouldDisplay =
        approvalFilter === "ALL" ||
        (approvalFilter === "UNCHECKED" && hasUnapprovedItems);

      return (
        matchesOption &&
        matchesLabel &&
        matchesProductName &&
        matchesVariant &&
        matchesClientBusinessName &&
        shouldDisplay
      );
    })


    .sort((a, b) => {
      // Convert the delivery dates to dayjs objects and reset the time to the start of the day.
      const dateA = dayjs(a.formData.deliveryDate).startOf("day");
      const dateB = dayjs(b.formData.deliveryDate).startOf("day");

      // Check if the delivery dates are the same.
      if (dateA.isSame(dateB)) {
        // If the dates are the same, sort by time.

        // Extract just the time portion (in HH:mm:ss format) from the productionTime.
        const timeA = dayjs(a.formData.productionTime).format("HH:mm:ss");
        const timeB = dayjs(b.formData.productionTime).format("HH:mm:ss");

        // Use localeCompare to sort the times (this will correctly sort strings like "00:00", "12:34", "23:45").
        return timeA.localeCompare(timeB);
      } else {
        // If the delivery dates are different, sort by date (earliest to latest).
        return dateA.isAfter(dateB) ? 1 : -1;
      }
    });

  if (isLoading) {
    return <Spin tip="Loading..." />;
  }

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  console.log('Filtered orders count:', filteredOrders.length);  // Add this line
  console.log('Current orders count:', currentOrders.length);

  return (
    <div>
      <Card>
      <Select
      value={selectedOptionFilter}
      style={{ width: 90 }}
      onChange={handleOptionFilterChange}
    >
      <Option value="All">Entrega</Option>
      <Option value="Despacho">Despacho</Option>
      <Option value="Retiro">Retiro</Option>
    </Select>

    <Select
      value={labelFilter}
      style={{ width: 90, marginLeft: "10px" }}
      onChange={handleLabelFilterChange}
    >
      <Option value="All">Área</Option>
      <Option value="Masas">Masas</Option>
      <Option value="Canapes">Canapes</Option>
      <Option value="Pasteleria">Pasteleria</Option>
      <Option value="Cocina">Cocina</Option>
      <Option value="Bebestibles">Bebestibles</Option>
      <Option value="Otros">Otros</Option>
    </Select>

    <Input
      value={productNameSearch}
      placeholder="Buscar producto"
      style={{ width: 150, marginLeft: "10px" }}
      onChange={handleProductNameSearchChange}
    />

    <Input
      value={variantSearch}
      placeholder="Buscar variante"
      style={{ width: 150, marginLeft: "10px" }}
      onChange={handleVariantSearchChange}
    />

    <Input
      value={clientNameSearch}
      placeholder="Buscar cliente"
      style={{ width: 150, marginLeft: "10px" }}
      onChange={handleClientNameSearchChange}
    />

    <RangePicker
      value={tempDateRange || dateRange}
      onChange={onDateRangeChange}
      onOpenChange={onDateRangeConfirm}
      format="YYYY-MM-DD"
    />

    <Select
      value={approvalFilter}
      style={{ width: 120 }}
      onChange={handleApprovalFilterChange}
    >
      <Option value="ALL">Todas</Option>
      <Option value="UNCHECKED">Pendientes</Option>
    </Select>

{currentUserRole !== "worker" && (
        <span style={{ marginLeft: "20px", fontSize: "0.9em" }}>
          Total: ${calculateSumOfTotals()}
        </span>
)}
        <span style={{ marginLeft: "20px", fontSize: "0.9em" }}>
          Cantidad: {calculateTotalDisplayedQuantities()}
        </span>
      </Card>
      <Card>
      {currentOrders
    ? currentOrders.map((order, index) => {
        console.log('Rendering order number:', index + 1);  // Add this line to log order number
        console.log('Order data:', order);  // Add this line to log order data
        return (
            <div style={{ marginBottom: "20px" }} key={order._id}>
                <ClientCard
                    id={order._id}
                    clientName={order.formData?.Nombre || ""}
                    businessName={
                        order.formData?.businessData?.nombreFantasia || ""
                    }
                    productList={order.formData?.tableData || []}
                    onOrderDetailsClick={handleOrderDetailsClick}
                    order={order}
                    labelFilter={labelFilter}
                    productNameFilter={productNameSearch}
                    variantFilter={variantSearch}
                    matchesLabelFilter={matchesLabelFilter}
                    matchesNameFilter={matchesNameFilter}
                    matchesVariantFilter={matchesVariantFilter}
                />
            </div>
        );
    })
    : "No Orders Found"}
        <Pagination
          current={currentPage}
          total={filteredOrders.length}
          pageSize={ordersPerPage}
          onChange={paginate}
        />
      </Card>
    </div>
  );
};

export default ListaOrdenes;
