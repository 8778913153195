import { Switch, Route, Redirect } from "react-router-dom";
import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import esES from 'antd/locale/es_ES';
import "antd/dist/reset.css";
import Home from "./pages/Home";
import ClientList from "./pages/clientList";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import FormSteps from './components/formulario/ordenes';
import Productos from './components/productos/productos';
import BusinessForm from './components/clientes/businessForm';
import Edit from './components/clientes/edit';
import EditNegocios from './components/clientes/editNegocios';
import EditProduct from './components/productos/editProduct';
import BusinessesList from './pages/businessesList';
import ProductList from './pages/productList';
import CreateAdapted from './components/formulario/pasoUno';
import ProductTable from "./components/formulario/pasoDos";
import FormModifiedDemo from './components/formulario/pasoTres';
import ListaOrdenes from './components/ordenes/listaOrdenes'
import ClientCard from './components/ordenes/tarjetaOrdenes'
import Usuarios from './components/usuarios/users'
import WooCommerceOrders from "./components/woocommerce/WooCommerceOrders";
import PrivateRoute from './components/usuarios/PrivateRoute'
import { useDispatch, useSelector } from "react-redux";
import { verifyUserToken } from "./components/redux/usersSlice";



function App() {
  const authStatus = useSelector(state => state.userManagement.authStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyUserToken()); 
  }, [dispatch]);

  if (authStatus === 'loading') {
    return <div>Loading...</div>;
  }


  return (
  
      <ConfigProvider locale={esES}>
        <div className="App">
          <Switch>
            <Route path="/sign-up" exact component={SignUp} />
            <Route path="/sign-in" exact component={SignIn} />
            <Route path="/" render={() => (
              <Main>
                <Switch>
                <PrivateRoute exact path="/dashboard" component={Home} roles={['admin']} />
                <PrivateRoute exact path="/client-list" component={ClientList} roles={['admin']}/>
                <PrivateRoute exact path="/businesses-list" component={BusinessesList} roles={['admin']}/>
                <PrivateRoute exact path="/billing" component={Billing} roles={['admin']}/>
                <PrivateRoute exact path="/rtl" component={Rtl} roles={['admin']}/>
                <PrivateRoute exact path="/usuarios" component={Usuarios} roles={['admin']}/>
                <PrivateRoute exact path="/ordenes" component={FormSteps} roles={['admin']}/>
                <PrivateRoute exact path="/paso-uno" component={CreateAdapted} roles={['admin']}/>
                <PrivateRoute exact path="/paso-dos" component={ProductTable} roles={['admin']}/>
                <PrivateRoute exact path="/woocommerce" component={WooCommerceOrders} roles={['admin']}/>
                <PrivateRoute exact path="/paso-tres" component={FormModifiedDemo} roles={['admin']}/>
               <PrivateRoute exact path="/productos" component={Productos} roles={['admin']}/>
               <PrivateRoute  exact path="/lista-ordenes" component={ListaOrdenes} roles={['admin', 'worker', 'delivery']}/>
               <PrivateRoute  exact path="/tarjeta" component={ClientCard} roles={['admin']}/>
               <PrivateRoute exact path="/business-form" component={BusinessForm} roles={['admin']} />
               <PrivateRoute  exact path="/product-list" component={ProductList} roles={['admin']}/>
               <PrivateRoute  exact path="/edit-negocios/:id" component={EditNegocios} roles={['admin']}/>
               <PrivateRoute  exact path="/edit/:id" component={Edit} roles={['admin']}/>
               <PrivateRoute  exact path="/edit-product/:id" component={EditProduct} roles={['admin']}/>
             
                <Redirect from="*" to="/dashboard" roles={['admin']}/>
                </Switch>
              </Main>
            )} />
          </Switch>
        </div>
      </ConfigProvider>
   
  );
}


export default App;
